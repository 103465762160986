// Basic menu blocks
// -----------------------------------------------------------------------------
ul.menu > li > ul.menu {
  margin-left: 1em; /* LTR */
}

// Nav
// -----------------------------------------------------------------------------
ul.nav {
  > li {
    &.dropdown {
      &:after {
        width: 100%;
        height: 2px;
        background: none;
        position: absolute;
        bottom: -2px;
        left: 0;
        right: 0;
        content: '';
      }
    }
  }
}

// Nav expanded
// -----------------------------------------------------------------------------
.nav-expanded {
  .caret {
    display: none;
  }
  > li {
    > a {
      font-size: 1.2em;
      text-align: left;
      font-weight: 700;
      color: $text-color;
      &:hover, &:focus, &.active, &.active-trail {
        color: $link-hover-color;
        background: none;
      }
    }
    ul.dropdown-menu {
      background: none;
      display: block;
      border: 0;
      padding: 0;
      position: static;
      float: none;
      border-radius: 0;
      box-shadow: none;
      li {
        a {
          padding: 3px 15px;
          color: $text-color;
          &:hover, &:focus, &.active, &.active-trail {
            color: $link-hover-color;
            background: none;
          }
        }
      }
      ul {
        display: none !important;
      }
    }
    @include breakpoint(md) {
      float: none;
      margin-bottom: 18px;
    }
    &.open {
      > a {
        color: $text-color;
        background: none;
        &:hover, &:focus, &.active, &.active-trail {
          color: $link-hover-color;
          background: none;
        }
      }
    }
  }
}

.navbar-default {
  background-color: white;
  border-color: white;
  z-index: 70;

  @media (min-width: 768px) {
    .navbar-collapse {
      padding-right: 0;
      padding-top: 25px;
      float: right;
    }
    #main-menu {
      text-align: right;
      margin-top: 15px;

      li {
        padding: 0;
        padding: 0 15px;
        &:last-child {
          padding-right: 0;
        }
      }

      a {
        color: black;
        display: inline-block;
        width: 100%;
        background: none;
        font-size: 1em;
        padding: 10px 0;
        &.active {
          &:after {
            content: "";
            display: inline-block;
            width: 120%;
            left: -10%;
            height: 3px;
            position: relative;
            bottom: -35px;
            background-color: $brand-primary;
          }
        }
      }
    }

  }
}

.navbar-brand {
  padding-top: 20px;
  height: auto;
  @include breakpoint(md) {
    padding: 0;
  }
}

.btn-group.user-menu {
  .caret {
    margin-right: 10px;
  }
}

#top-menu {
  .region-top-menu {
    text-align: right;
    .btn-group button {
      height: 40px;
    }

    .btn {
      padding: 0;
      border-color: #EB6A54;
    }

    .dropdown-menu, .dropdown-toggle {
      background-color: #D95B43;
    }

    .btn a, .dropdown-menu li a, .dropdown-toggle {
      color: white;
      //font-weight: 600;
      i {
        padding-right: 5px;
      }

      &:focus {
        background-color: #EB6A54;
      }

      &:hover {
        background-color: #EB6A54;
        text-decoration: none;
      }
    }

    a .item-name {
      display: none;

      @include breakpoint(md) {
        display: inline-block;
      }
    }

    .btn a, .btn.dropdown-toggle {
      padding: 10px 15px;
      display: inline-block;
    }

    .btn-group > .btn {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
  //padding-bottom: 25px;
}
