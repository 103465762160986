// Layout
// --------------------------------------------------
header.header {
  background-color: white;
  height: 160px;
}

// .container {
//   .container,
//   .container-fluid {
//     width: auto;
//     margin-left: -15px;
//     margin-right: -15px;
//   }
// }

#footer {
  margin: 0px 0;
  padding: 3em 0;
  background-color: #343838;
  color: white;
}
